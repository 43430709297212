@import '../../../../app/styles/mixins';

.tabs {
  background-color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 52px;

  & .tabsLabel {
    display: flex;
    align-items: center;

    & .title {
      @include for-tablet-down {
        max-width: 130px;
      }
    }

    & .objectImage {
      width: 21px;
      height: 27px;
      margin-left: 6px;
    }

    & .objectCompletedIcon {
      width: 20px;
      height: 20px;
    }
  }

  @include for-tablet-only {
    justify-content: flex-start;
    padding-inline: 24px;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: var(--dynamic-color) !important;
  }

  :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-color: var(--dynamic-color) !important;
    border-bottom: 3px solid var(--dynamic-color) !important;
    transition: all 0.5s;
  }
  :global(.ant-tabs-ink-bar) {
    transition: none !important;
    display: none;
  }

  :global(.ant-tabs-tab) {
    border-bottom: 3px solid transparent;

    @include for-desktop-up {
      width: 258px;
    }

    align-items: center;
    justify-content: center;
    display: flex;

    @include for-desktop-down {
      width: 180px;
      padding: 10px 0;
    }

    @include for-tablet-down {
      width: 140px;
    }

    padding: 10px 0;
  }

  :global(.ant-tabs-tab-btn) {
    color: #444 !important; 
    opacity: 0.75;
    overflow: hidden;
    text-overflow: ellipsis;

    @include for-tablet-down {
      max-width: 150px;
    }
  }

  :global(.ant-tabs-tab-btn-active) {
    color: var(--dynamic-color) !important;
  }

  :global(.ant-tabs-nav::before) {
    display: none;
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 0;
    margin-top: 10px;
  }

  :global(.ant-tabs-tab) {
    padding-top: 0;
    padding-bottom: 8px;

    @include for-tablet-down {
      margin-left: 4px !important;
    }
  }
}