@import '../../../../app/styles/mixins';

.padding {
  padding: 0 88px;

  @include for-desktop-down {
    padding: 0 24px;
  }

  @include for-phone-only {
    padding: 0 24px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px 0;

  @include for-desktop-down {
    padding: 48px 0 56px;
  }

  @include for-tablet-down {
    padding: 48px 0 56px;
  }

  @include for-phone-only {
    padding: 32px 0;
  }
}

.titleWrapper {
  composes: padding;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.title {
  //composes: padding;
  margin: 0;
  margin-bottom: 8px !important;

  @include for-desktop-down {
    font-size: 20px !important;
    line-height: 27px;
  }
}

.subTitle {
  composes: padding;
  margin: 0 0 22px !important;
  margin-top: 0 !important;

  @include for-tablet-only {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 0 0 12px !important;
  }

  @include for-phone-only {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 0 0 12px !important;
  }
}

.list {
  display: grid;
  grid-auto-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  grid-column-gap: 16px;
  padding: 12px 88px 20px;
  overflow-x: auto;
  overflow-y: auto;

  @include hide-scrollbar;

  @include for-desktop-down {
    grid-column-gap: 12px;
  }

  @include for-desktop-down {
    margin: 0 24px;
    padding: 0;
  }
}

.button {
  width: fit-content;
  margin: 40px auto 0;

  @include for-desktop-down {
    width: 340px;
  }

  @include for-phone-only {
    width: 100%;
    max-width: 340px;
  }
}
