.card {
  width: 654px;
}

.row {
  display: flex;
  column-gap: 16px;
}

.calendarsRow {
  padding-bottom: 34px;
}

.col {
  flex: 1;
  max-width: calc(50% - 8px);
}

.inputText {
 & > input {
   font-size: 16px;
   font-weight: 400;
   color: #191919;
 }
}

.inputTextArea {
  & > textarea {
    background-color: transparent !important;
  }
}