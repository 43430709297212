@import '../../../../app/styles/mixins';

.desktopWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 88px;
  gap: 16px;
  background-color: #fff;
  height: 81px;
  box-shadow: 4px -4px 20px 0px #0000001a;
}

.desktopButton {
  overflow: visible !important;
}

.wrapper {
  :global(.ant-collapse) {
    bottom: 0;
    left: 0;
    width: 351px;
    height: 49px;
    border: 0;
    border-radius: 0;
    filter: drop-shadow(0 4px 24px #0000001a);

    @include for-desktop-down() {
      margin-left: 24px;
      margin-bottom: 24px;
    }

    @include for-phone-only() {
      margin: 0;
      right: 0;
      width: 100%;
    }

    :global(.ant-collapse-item) {
      :global(.ant-collapse-content) {
        display: none;
      }
    }

    :global(.ant-collapse-item) {
      :global(.ant-collapse-content-active) {
        display: flex;
      }
    }

    :global(.ant-collapse-item) {
      border-radius: 0;
      border: 0;
      height: 100%;

      &:global(.ant-collapse-item-active) {
        :global(.ant-collapse-header) {
          border-radius: 0 0 6px 6px;
        }
      }

      :global(.ant-collapse-header) {
        align-items: center;
        gap: 24px;
        height: 100%;
        padding: 8px 16px;
        border-radius: 6px;
        background-color: #fff;
        overflow: hidden;

        @include for-phone-only() {
          border-radius: 0;
        }

        :global(.ant-collapse-expand-icon) {
          padding-inline-end: 0;
        }

        > span {
          > div {
            display: flex;
            align-items: center;

            @media (width <= 600px) {
              justify-content: space-between !important;
            }

            gap: 16px;

            .button {
              padding: 0;
              height: 20px;
              color: var(--primary-color);
              font-size: 12px;
              font-weight: bold;

              span {
                text-decoration: underline;
              }

              @include for-desktop-down() {
                margin-left: auto;
              }
              @include for-phone-only() {
                margin-left: 0;
              }
            }

            > .status {
              .statusHeadline {
                margin-bottom: 0;
                color: #616161;
                font-size: 10px;
                font-weight: 600;
              }

              .statusText {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                line-height: normal;

                & .campaignObject {
                  width: 26px;
                  height: 31px;
                  margin-left: 4px;
                }

                & .campaignCompletedIcon {
                  width: 22px;
                  height: 22px;
                }

                > span {
                  color: #858899;
                  font-size: 14px;
                  font-weight: 400;
                  > div {
                    vertical-align: top;
                    display: inline-block;
                  }
                }

                @include for-phone-only() {
                  font-size: 16px;
                  & > span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    :global(.ant-collapse-content) {
      transform: rotate(180deg);
      border-radius: 16px 16px 0 0;
      border-top: 1px solid rgb(68 68 68 / 20%);
      background: #fff;
      position: absolute;
      z-index: 1;
      min-height: 430px;
      transform-origin: top;
      width: 351px;
      top: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      :global(.ant-collapse-content-box) {
        transform: rotate(180deg);
        height: 100%;
        width: 100% !important;
      }

      @include for-phone-only() {
        width: 100%;
      }
    }
  }
}

.mobileLevelsListWrapper {
  width: 100%;
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 16px 16px 16px 12px;
}

.sublevels {
  display: flex;
  flex-direction: column-reverse;
}

.title {
  margin-bottom: 4px;
  font-size: 20px !important;
}

.pointsInfo {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #616161 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
