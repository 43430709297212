@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 24px;

  @include for-desktop-up {
    padding: 40px 88px 43px;
  }

  @include for-desktop-down {
    gap: 24px;
    padding-bottom: 0;
  }

  @include for-phone-only {
    padding: 32px 16px 8px;
  }

  .header {
    display: grid;
    grid-template-columns: 80% 1fr;
    grid-template-rows: repeat(1, 1fr);

    @include for-desktop-down {
      grid-template-rows: repeat(1, 1fr);
    }

    & .headerWrapper {
      display: flex;
      align-items: center;

      & .campaignObject {
        width: 32px;
        height: 42px;
        margin-left: 10px;
      }
    }
  }

  .title {
    margin: 0;
    margin-bottom: 8px !important;
    line-height: 38px;
    grid-area: 1 / 1 / 2 / 2;

    @include for-desktop-down {
      font-size: 20px !important;
      line-height: 28px;
    }
  }

  .subTitle {
    margin: 0 !important;
    line-height: 32px;
    grid-area: 2 / 1 / 3 / 3;
    margin-top: 0 !important;

    @include for-desktop-down {
      font-size: 14px !important;
      line-height: 19px;
      font-weight: 400 !important;
    }
  }

  .categoriesAmount {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
    justify-self: flex-end;

    @include for-desktop-down {
      grid-area: 3 / 1 / 4 / 3;
      justify-self: flex-start;
      margin-top: 16px;
      font-size: 14px !important;
      line-height: 19px;
    }
  }
}

.button {
  margin: 8px auto 0;
  display: block;
  text-align: center;
  padding-inline: 33.5px;


  @include for-desktop-down {
    width: 220px;
    margin-top: 8px;
    padding-inline: 24px;
  }

  @include for-phone-only {
    width: calc(100% - 32px);
  }
}
