.wrapper {
  color: #444;
  min-width: max-content;

  .header {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .title {
    margin-right: 6px;
    color: currentColor !important;
  }

  .pointsSection {
    display: flex;
    align-items: baseline;
  }

  .currentPoints {
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 38px;
    color: currentColor;
    font-weight: bold;
  }

  .points {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 27px;
    color: #858899;
  }

  .campaignElement {
    width: 24px;
    height: 29px;
    margin-left: 4px;
  }

  .campaignCompletedObject {
    width: 20px;
    height: 20px;
  }

  .description {
    display: block;
    color: currentColor;
    line-height: 16px;
    font-size: 12px;
  }

  .remainingPoints {
    display: inline-flex;
    align-items: center;
  }
}
