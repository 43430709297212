.switchContainer {
  display: flex;
  column-gap: 8px;
  margin-bottom: 16px;

  & .switchFormItem {
    min-height: 20px;
    max-height: 20px;
  }

  & .title {
    font-size: 16px;
    font-weight: 600;
  }
}

.card {
  width: 654px;
}