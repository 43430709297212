@mixin for-phone-only {
  @container (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-only {
  @container (min-width: 600px) and (max-width: 1199px) {
    @content;
  }
}

@mixin specific-small-dDesktop {
  @container (min-width: 1201px) and (max-width: 1300px) {
    @content;
  }
}

@mixin for-large-desktop-up {
  @container (min-width: 1441px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @container (min-width: 600px) and (max-width: 1199px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-desktop-up {
  @container (min-width: 1200px) {
    @content;
  }
}

@mixin for-tablet-down {
  @container (max-width: 900px) {
    @content;
  }
}

@mixin for-desktop-down {
  @container (max-width: 1200px) {
    @content;
  }
}

@mixin forSmallDesktop {
  @container (min-width: 1361px) and (max-width: 1441px) {
    @content;
  }
}

@mixin forLaptops {
  @container (max-width: 1360px) {
    @content;
  }
}

@mixin for-small-tablet {
  @container (max-width: 778px) {
    @content;
  }
}

@mixin forLargeDesktop {
  @container (min-width: 1600px) {
    @content;
  }
}

@mixin forLargeTablet {
  @container (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
