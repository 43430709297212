.container {
  display: flex;
  column-gap: 16px;

  & .number {
    width: 32px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 10.2px;
  }

  & .col {
    width: 184px;
  }

  & .removeButton {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5C5C6E;
    transition: 0.3s;

    &:hover {
      border-color: #DA1B5E;
    }

    &:hover svg {
      fill: #DA1B5E
    }

    & svg {
      transition: 0.3s;
      fill: #5C5C6E
    }
  }

  & .copyButton {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5C5C6E;
    transition: 0.3s;

    &:hover {
      border-color: #1E8D8A;
    }

    &:hover svg {
      fill: #1E8D8A;
    }

    & svg {
      transition: 0.3s;
      fill: #5C5C6E;
    }
  }
}