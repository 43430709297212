.container {
  min-width: calc(50% - 8px);
  position: relative;

  & .input {
    & > input {
      font-weight: 400;
      font-size: 16px;
      padding-left: 28px;
    }
  }

  & .colorCircle {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    position: absolute;
    left: 10px;
    top: 6px;
    z-index: 1000;
    pointer-events: none;
  }
}

.pickerWrapper {
  border-radius: 8px;
  background: #222;
  box-shadow: 0 0 6px rgba(0, 0, 0, .25);
  padding: 12px;
}