@import '../../../../app/styles/mixins';

.wrapper {
  padding: 62px 16px 16px;
  width: 200px;
  min-height: 200px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  border: 1px dashed currentColor;
  position: relative;
  cursor: pointer;
  transition: .2s ease;

  &:hover {
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 25%);
    transform: scale(1.04);
  }

  @container (max-width: 1363px) {
    padding: 16px;
    width: 156px;
    margin: 0 8px;
    min-height: 128px;
  }

  @include for-desktop-down {
    padding: 16px;
    width: 156px;
    min-height: 128px;
    margin: 0 8px;
  }

  @include for-tablet-down {
    width: 142px;
    min-height: 126px;
    margin: 0 8px;
  }

  .iconCheck {
    align-self: flex-end;
    margin-bottom: 20px;
    background-color: white;
    position: absolute;
    right: 0%;
    top: 0%;
  }

  .campaignImage {
    width: 25px;
    height: 32px;
  }

  .campaignImageCompletedIcon {
    width: 20px;
    height: 20px;

    & > svg {
      width: 13px;
      height: 13px;
    }
  }

  .points {
    position: absolute;
    right: 0%;
    top: 0%;
    align-self: flex-end;
    line-height: 1.3;
    padding: 14px 14px 0 0;
    color: currentColor !important;
    margin-bottom: 20px;
  }

  .iconCategory {
    margin-bottom: 16px;
    width: 50px;
    height: 50px;

    @container (max-width: 1363px)  {
      margin-bottom: 12px;
      width: 24px;
      height: 24px;
    }

    @include for-desktop-down {
      margin-bottom: 12px;
      width: 24px;
      height: 24px;
    }
  }

  .name {
    display: block;
    font-weight: bold;
    line-height: 28px;
    color: currentColor !important;
    hyphens: auto;

    @include for-desktop-down {
      font-size: 14px !important;
      line-height: 19px;
    }
  }
}

.active {
  border-style: solid;
  background: color-mix(in srgb, currentColor 20%, transparent);
}
