.container {
  display: flex;

  :global(.ant-tabs-nav) {
    margin: -24px -32px 0;
    padding: 0 24px;
    background-color: #404055;
    height: 56px;
    display: flex;
    justify-content: space-between;
  }

  :global(.ant-tabs-nav-wrap) {
    flex-grow: 0 !important;
  }

  :global(.ant-tabs-nav-list) {
    display: flex;
    align-items: center;
  }

  :global(.ant-tabs-ink-bar) {
    display: none;
  }

  :global(.ant-tabs-tab-active) {
    height: 32px;
    background-color: #5C5C6E;
    border-radius: 8px;
    padding-bottom: 0;
    width: 120px;
    display: flex;
    justify-content: center;
    & > div {
      color: #ffffff !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  :global(.ant-tabs-tab) {
    height: 32px;
    width: 120px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    & > div {
      color: #ffffff !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  & .rightButtonsContainer {
    display: flex;
    column-gap: 16px;
  }

  & .btn {
    min-width: 80px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .deleteButton {
    background-color: #404055;
    color: #ffffff;
    transition: background-color 0.2s ease, opacity 0.2s ease;

    &:hover {
      background-color: #5a5a75 !important;
      color: #ffffff !important;
      border: 1px solid #ffffff !important;
    }

    &:active {
      background-color: #323247 !important;
      opacity: 0.8;
    }
  }

  & .saveButton {
    background-color: #00765F;
    color: #ffffff;
    transition: background-color 0.2s ease, opacity 0.2s ease;

    &:hover {
      background-color: #009679 !important;
      color: #ffffff !important;
    }

    &:active {
      background-color: #005a48 !important;
      opacity: 0.8;
    }
  }


  & .backButton {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    transition: color 0.2s ease, opacity 0.2s ease;
    height: 56px;

    &:hover {
      color: #f0f0f5 !important;
    }

    &:active {
      color: #d9d9de !important;
      opacity: 0.8;
    }

    & svg path {
      stroke: #CFCFD4;
      transition: stroke 0.2s ease;
    }

    &:hover svg path {
      stroke: #ffffff;
    }

    &:active svg path {
      stroke: #d9d9de;
    }
  }
}