.container {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;


  & .imagePickerWrapper {
    display: flex;
    width: 266px;
  }

  & .wrapperOnScroll {
    width: 252px;
  }

  & .inputWrapper {
    width: 266px;
  }

  & .inputText {
    & > input {
      font-size: 16px;
      font-weight: 400;
      color: #191919;
    }
  }

  & .removeButton {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5C5C6E;
    transition: 0.3s;

    &:hover {
      border-color: #DA1B5E;
    }

    &:hover svg {
      fill: #DA1B5E
    }

    & svg {
      transition: 0.3s;
      fill: #5C5C6E
    }
  }
}

.pictureClassName {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  flex-grow: 0;
}

.imageWrapperClassName {
  display: flex;
  height: 40px;
  flex-grow: 0;

  & > div + span {
    width: 172px;
  }
}

.scrollRightPadding {
  padding-right: 12px;
}