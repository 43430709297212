.container {
  padding-top: 24px;

  .rulesListContainer {
    display: flex;
    column-gap: 16px;

    & .btn {
      display: flex;
      align-items: center;
      font-weight: 600;
      height: 32px;
      padding: 8px 12px;
      border-radius: 16px;
      background-color: #FFFFFF;
      color: #191919;
      transition: background-color  0.3s ease, opacity 0.3s ease;
      border: 1px solid #191919;

      &:hover {
        background-color: #5a5a75 !important;
        color: #ffffff !important;
      }

      &:active {
        background-color: #323247 !important;
        opacity: 0.8;
      }
    }

    & .btnSelected {
      background-color: #00765F;
      color: #ffffff;
      border: 1px solid transparent;

      &:hover {
        background-color: #009679 !important;
        color: #ffffff !important;
      }

      &:active {
        background-color: #005a48 !important;
        opacity: 0.8;
      }
    }
  }

  & .selectedRuleContainer {
    padding-top: 32px;

    & .row {
      display: flex;
      justify-content: space-between;

      & .ruleNameType {
        display: flex;
        column-gap: 16px;
      }

      & .editRuleButton {
        width: max-content;
        padding: 0;
        transition: 0.3s;
        font-size: 16px;
        font-weight: 600;
        color: #00765F;

        &:hover {
          color: #009F80 !important;
        }

        &:active {
          color: #005a48 !important;
          opacity: 0.8;
        }

        &:disabled {
          &:hover {
            color: #9696A2 !important;
          }
        }
      }
    }
  }
}

