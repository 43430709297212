.container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #CFCFD4;
  padding-bottom: 14px;
  row-gap: 16px;

  :global(.ant-btn-link) {
    &:hover {
      color: #DA1B5E !important;
    }
  }

  & .button {
    display: flex;
    align-items: center;
    color: #DA1B5E;
    font-weight: 700;
    font-size: 16px;
    padding-right: 12px;
    border-right: 1px solid #CFCFD4;
    border-radius: 0;
    height: 18px;
    column-gap: 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }

    &:disabled {
      color: #858899;
      &:hover {
        color: #858899 !important;
      }
    }

    & .icon {
      transform: rotateX(180deg);
      transition: 0.2s ease-in-out;
    }

    & .arrowClosedState {
      transform: rotateX(0deg);
    }
  }
}

.operatorsPopup {
  & .dropdownMenu {
    width: 179px;
    border-radius: 8px;
    border: 1px solid #5C5C6E;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 10px 0 14px;

    & .operatorButton {
      font-size: 16px;
      line-height: 16px;
      transition: 0.3s ease;
      height: 30px;
      padding: 0 16px;
      color: #191919;
      text-align: start;
      border-radius: 0;

      & > span {
        display: block;
      }

      &:hover {
        background-color: #F5F5F6;
        color: #191919;
      }

      &:hover span {
        color: #191919
      }
    }
  }
}