.card {
  width: 654px;

  & .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 32px;
    display: block;
  }

  & .wrapper {
    border-top: 1px solid #E3E3E6;
    padding: 24px 24px 0;
    margin: 0 -24px
  }

  & .row {
    display: flex;
    justify-content: space-between;
  }
}

.inputText {
  & > input {
    font-size: 16px;
    font-weight: 400;
    color: #191919;
  }
}

.appSegment {
  min-height: auto;
}