@import "../../app/styles/mixins";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 110px;
  column-gap: 8px;

  @include rwd(1440) {
    height: 68px;
  }

  @include for-tablet-only {
    display: none;
  }

  @include for-phone-only {
    display: none;
  }

  & .elementsContainer {
    display: flex;
    flex: 1;

    & .elementItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 110px;

      @include rwd(1440) {
        height: 68px;
        width: 82px;
      }

      & .foundElement {
        width: 49px;
        height: 62px;

        @include rwd(1440) {
          width: 33px;
          height: 44px;
        }
      }

      & .emptyPlaceholder {
        position: relative;
        z-index: 100;
        width: 80px;
        height: 80px;

        @include rwd(1440) {
          height: 46px;
          width: 46px;
        }
      }
    }

    & .hiddenContainer {
      position: relative;
      background-color: rgba(255, 255, 255, 0.5);

      backdrop-filter: grayscale(1);
    }

  }

  & .badge {
    width: max-content;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 13px 22px;
    margin-right: 40px;
    margin-left: 42px;
    column-gap: 32px;
    cursor: pointer;

    @include rwd(1440) {
      height: 54px;
      padding: 10px 16px;
    }

    & .badgeTitle {
      font-size: 16px !important;
      line-height: 21px !important;
      margin: 0;
      width: 100px;

      @include rwd(1440) {
        font-size: 14px !important;
        width: 88px;
      }
    }

    & .badgeCounter {
      margin: 0;
      font-size: 50px !important;

      @include rwd(1440) {
        font-size: 42px !important;
      }
    }
  }
}

.badgeMobileTablet {
  width: max-content;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 12px;
  margin-right: 16px;
  cursor: pointer;

  & .badgeCounter {
    margin: 0;
    font-size: 28px !important;
  }
}

.overlay {
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
  z-index: 999;
}

.overlayOpen {
  position: absolute;
  display: block;
}