.switchContainer {
  display: flex;
  column-gap: 8px;
  margin-bottom: 32px;

  & .switchFormItem {
    min-height: 20px;
    max-height: 20px;
  }

  & .title {
    font-size: 16px;
    font-weight: 600;
  }
}

.colorSelect {
  max-width: 100%;
}

.container {
  margin: 0 -24px;
  padding: 24px 24px 0;
  border-top: 1px solid #E3E3E6;
}