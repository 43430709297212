.card {
  width: 104px;
  height: 114px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  position: relative;

  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05),
  1px 4px 5px 0px rgba(0, 0, 0, 0.3);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 10px;

    .icon {
      position: absolute;
      transform: translate(-50%);
      top: 15%;
      left: 50%;
    }

    .campaignObjectCompletedIcon {
      width: 20px;
      height: 20px;

      & > svg {
        width: 12px;
        height: 12px;
      }
    }

    .campaignElementImage {
      width: 25px;
      height: 32px;
    }
  }

  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3) inset;

  &_new {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
    border-radius: 16px;
  }
}

.sliderWrapper {
  width: 100%;
  overflow: hidden;
}

.sliderWrapper > div > div > div {
  display: flex;
  gap: 8px;
}

.selectWrapper {
  :global(.ant-select) {
    min-height: 51px;
    width: 100%;

    :global(.ant-select-selector) {
      color: #fff !important;
      border-radius: 0;
      padding: 16px;
    }
  }
}

.selectLabelWrapper {
  display: flex;
  align-items: center;

  & .campaignObjectImage {
    width: 21px;
    height: 29px;
    margin-left: 10px;
  }

  & .campaignObjectCompletedIcon {
    width: 20px;
    height: 20px;
  }
}

.underline {
  margin-top: 20px;
  min-width: 100px;
  height: 3px;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-align: center;
  hyphens: auto;
}
