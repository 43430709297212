.datePickerFormItem {
  min-height: auto;
  width: 100%;
  height: 40px;

  .calendarIcon {
    position: absolute;
    right: 10px;
    left: auto;
    z-index: 2;
    top: 12px;
    pointer-events: none;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container) {
    height: 40px;

    & > div {
      height: 40px !important;

      & > span {
        height: 40px !important;
      }
    }
  }

  :global(.react-datepicker__close-icon::after) {
    min-width: 12px;
    min-height: 12px;
    background-color: #9696a2;
  }

  :global(.ant-input-suffix) {
    display: none;
  }

  :global(.DatePicker) {
    input {
      height: 40px;
    }
  }
}

.inputLabel {
  left: 16px !important;
  top: 12px;
  font-size: 16px !important;
}

.inputInner {
  & > input {
    font-size: 16px !important;
    font-weight: 400;
    color: #191919
  }
}