
.headlineBlock {
  position: relative;
  margin-bottom: 24px;

  & .campaignElement {
    display: inline-block;
    margin-left: 16px;
  }
}

.campaignBadge {
  width: 112px;
  height: 112px;
  z-index: 100;
  position: absolute;
  bottom: -35%;
  left: 65%;
  border-radius: 50%;
  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }
}