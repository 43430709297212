.container {
  .btnContainer {
    padding: 14px 24px;
    display: flex;
    justify-content: flex-end;

    & .btn {
      width: 42px;
      height: 24px;
      background-color: #00765F;
      font-size: 12px;
      font-weight: 700;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #005a48;
      }

      &:active {
        background-color: #004d3e;
        transform: scale(0.95);
      }
    }
  }
}

