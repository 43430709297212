.card {
  width: 654px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.listHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
  margin-bottom: 6px;

  & .listHeaderItem {
    width: 50%;
    font-size: 14px;
    font-weight: 600;
  }
}

.addObjectButton {
  width: max-content;
  padding: 0;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 600;
  color: #00765F;

  &:hover {
    color: #009F80 !important;
  }

  &:active {
    color: #005a48 !important;
    opacity: 0.8;
  }

  &:disabled {
    &:hover {
      color: #9696A2 !important;
    }
  }
}