@import '../../../../app/styles/mixins';

.padding {
  padding: 0 88px;

  @include for-desktop-down {
    padding: 0 24px;
  }

  @include for-phone-only {
    padding: 0 16px;
  }
}


.container {
  display: flex;
  padding: 40px 88px;
  width: 100%;

  @include for-phone-only {
    flex-direction: column-reverse;
  }

  & .leftContainer {
    width: 50%;

    @include for-large-desktop-up {
      padding: 48px 60px;
    }

    @include rwd(1440) {
      padding: 48px 60px;
    }

    @include for-tablet-only {
      height: auto;
      padding: 40px;
    }

    @include for-phone-only {
      padding: 40px;
      width: 100%;
    }

    & .headlineOverride {
      font-weight: 700 !important;
      margin-bottom: 0;

      @include for-large-desktop-up {
        font-size: 48px !important;
      }

      @include rwd(1440) {
        font-size: 38px !important;
      }

      @include for-tablet-landscape-up {
        font-size: 30px !important;
      }

      @include for-tablet-only {
        font-size: 30px !important;
      }

      @include for-phone-only {
        font-size: 30px !important;
      }
    }

    & .sublineOverride {
      font-weight: 500 !important;
      font-size: 16px !important;
      margin-top: 16px;

      @include for-large-desktop-up {
        font-size: 20px !important;
        line-height: 28px !important;
        margin-bottom: 40px;
      }

      @include rwd(1440) {
        font-size: 16px !important;
        line-height: 22px !important;
        margin-bottom: 32px;
      }

      @include for-tablet-landscape-up {
        font-size: 16px !important;
        margin-bottom: 32px;
      }

      @include for-tablet-only {
        font-size: 14px !important;
        margin-bottom: 32px;
      }

      @include for-phone-only {
        font-size: 14px !important;
        margin-bottom: 32px;
      }
    }

    & .buttonsRow {
      display: flex;
      column-gap: 16px;

      @include for-phone-only {
        flex-direction: column;
        row-gap: 16px;
      }
    }
  }

  & .rightContainer {
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include for-large-desktop-up {
      padding: 48px 60px;
    }

    @include rwd(1440) {
      padding: 48px 60px;
    }

    @include for-tablet-only {
      height: auto;
      padding: 40px;
    }

    @include for-phone-only {
      height: 306px;
      padding: 40px;
      width: 100%;
    }
  }

  @include for-desktop-down {
    padding: 48px 24px 56px;
  }

  @include for-tablet-down {
    padding: 48px 24px 56px;
  }

  @include for-phone-only {
    padding: 32px 16px;
  }
}