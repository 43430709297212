.switchContainer {
  display: flex;
  column-gap: 8px;
  margin-bottom: 16px;

  & .switchFormItem {
    min-height: 20px;
    max-height: 20px;
  }

  & .title {
    font-size: 16px;
    font-weight: 600;
  }
}

.card {
  width: 654px;

  & .subTitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
    display: block;
  }

  & .row {
    display: flex;
    column-gap: 16px;
  }
}

.rightSideImageContainer {
  margin: 0 -24px;
  padding: 24px 24px 0;
  border-top: 1px solid #E3E3E6;
}