.container {
  display: block;
  margin-bottom: auto;
  position: relative;
  width: max-content;

  & .completedContainer {
    visibility: visible;
    transition: 0.3s;
    position: absolute;
    right: -10px;
    top: -6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #60B11F;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg path {
      fill: #ffffff
    }
  }

  & .isNotCompleted {
    visibility: hidden;
  }

  & .img {
    width: 37px;
    height: 48px;
    cursor: pointer;
    //box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  }
}