@import '../../../../app/styles/mixins';

.cardWrapper {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  padding: 18px 8px;
  cursor: pointer;
  height: 100%;

  @include for-phone-only {
    margin-bottom: 0;
    width: 100%;
    padding: 0 16px;
  }

  & :hover .eyeWrapper {
    opacity: 1;
  }

  .eyeWrapper {
    @include for-desktop-down {
      opacity: 1;
    }

    opacity: 0;
    margin-right: auto;
    margin-left: 16px;

    &.eyeActive {
      opacity: 1;
    }
  }

  &.overlayActive {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(255 255 255 / 85%);
      z-index: 11;
    }

    & .overlayText {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-self: center;
      color: #000;
      font-size: 20px;
      z-index: 100;
      padding: 0 32px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 363px;
  padding: 16px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  transition: 0.2s;
  border: 2px solid transparent;
  position: relative;
  min-height: 466px;
  //flex-grow: 1;

  @include for-tablet-only {
    min-height: 408px;
  }

  &_containerRecommended {
    border: 2px solid;
  }

  @include for-tablet-only {
    width: 300px;
  }

  @include for-phone-only {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 25%);
  }

  & .recommendationFrame {
    position: absolute;
    z-index: 1000;
    width: calc(100% + 4px);
    left: -2px;
    top: -13px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    @include for-phone-only {
      top: -9px;
    }
  }

  & .header {
    & .imageContainer {
      position: relative;

      & .headerOptions {
        top: 10px;
        right: 10px;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & .countOfProducts {
          padding: 5px 11px 5px 8px;
          display: flex;
          column-gap: 3px;
          align-items: center;
          height: 38px;
          width: max-content;
          margin-right: 8px;

          &.eyeActive {
            position: relative;
            z-index: 1000;
          }

          & > span {
            color: white !important;
          }
        }
      }

      & .badgeWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 10px;
        padding: 5px 8px;
      }
    }

    & .image {
      display: block;
      width: 100%;
      height: 186px;
      object-fit: cover;

      @include for-phone-only {
        height: 153px;
      }

      @include for-tablet-only {
        height: 153px;
      }
    }

    & .hint {
      display: block;
      margin-top: 6px;
      height: 16px;
    }

    .titleHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 16px;
      column-gap: 12px;

      & .productName {
        margin: 0;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-height: 2.5em;
        hyphens: auto;

        @include for-phone-only {
          font-size: 16px !important;
          font-weight: 700 !important;
        }

        @include for-tablet-only {
          font-size: 16px !important;
          font-weight: 700 !important;
        }
      }

      & .pointsContainer {
        display: flex;
        column-gap: 4px;
        justify-content: center;
        align-items: center;

        & .pointsIcon {
          width: 16px;
          height: 16px;
        }

        & .productPoints {
          margin: 0;
          width: max-content;

          @include for-phone-only {
            font-size: 16px !important;
            font-weight: 700 !important;
          }

          @include for-tablet-only {
            font-size: 16px !important;
            font-weight: 700 !important;
          }
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .mainContainer {
    margin-top: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include for-tablet-only {
      margin-top: 16px;
    }

    @include for-phone-only {
      margin-top: 16px;
    }

    & .subHeader {
      padding-inline: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;

      @include for-phone-only {
        padding: 0;
      }

      @include for-tablet-only {
        padding: 0;
      }

      & .subTitle {
        @include for-phone-only {
          font-size: 12px !important;
        }

        @include for-tablet-only {
          font-size: 12px !important;
        }
      }

      & .toggleButton {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: #00a775;
        padding: 0;

        & .detailsOpened {
          transform: rotateX(-180deg);
        }

        & .buttonText {
          @include for-phone-only {
            font-size: 12px !important;
          }

          @include for-tablet-only {
            font-size: 12px !important;
          }
        }

        &:hover {
          background: transparent;
        }
      }
    }

    & .details {
      margin-top: 12px;
      padding-inline: 16px;
      opacity: 85%;

      @include for-phone-only {
        padding: 0;
        margin-top: 0;
      }

      @include for-tablet-only {
        padding: 0;
        margin-top: 0;
      }

      & .description {
        line-height: 20px;
      }

      & .bulletPoints {
        margin: 16px 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        @include for-phone-only {
          margin: 10px 0 0;
        }

        & .campaignObjectContainer {
          padding-left: 32px;
        }

        & .bulletItem {
          display: flex;
          column-gap: 8px;

          & .bulletIcon {
            width: 24px;
            height: 24px;
          }

          & .bulletText {
            line-height: 20px;
            margin: 0;
          }
        }
      }

      & .price {
        display: block;
        margin-top: 16px;
      }
    }

    & .buttonGroup {
      z-index: 10;
      background-color: #fff;
      margin-top: 24px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 16px;

      & .button {
        padding: 0;
      }
    }
  }
}
