@import '../../../../app/styles/mixins';

.mainContainer {
  position: relative;
  flex-shrink: 0;
  width: 704px;
}

.section {
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  min-height: 703px;
  align-items: center;
  justify-content: center;
  gap: 18px;
  background-color: linear-gradient(68deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 64.29%);
  display: flex;
  padding: 24px 27px;
  flex-wrap: wrap;

  @include for-desktop-up {
    flex-wrap: nowrap;
  }
}

.headlineBlock {
  max-width: 548px;
  margin-right: auto;

  & .campaignElementContainer {
    display: inline-block;
    margin-left: 16px;
  }

  & .campaignElementCompletedIcon {
    top: -1px;
    right: -7px;
  }

  .title {
    margin-bottom: 16px;
    word-break: normal !important;
    overflow-wrap: normal;
    color: #fff !important;
    width: 800px;
    max-width: calc(100vw - 54px);

    @include for-desktop-up {
      width: 16ch;
    }
  }

  .subTitle {
    margin-bottom: 40px;

    @include for-tablet-only {
      margin-bottom: 4px;
      max-width: 90%;
    }
  }

  @include for-desktop-up {
    margin-right: 0;
  }
}

.campaignBadge {
  width: 156px;
  height: 156px;
  position: absolute;
  top: -2%;
  left: 73%;
  border-radius: 50%;
  background: transparent;
  border: none;
  z-index: 101;

  &:hover {
    background: transparent !important;
  }
}

.circleButton {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  z-index: 100;
  position: absolute;
  top: -3%;
  left: 73%;
  background: linear-gradient(180deg, #60b11f 0%, #007829 100%);
  filter: drop-shadow(0 4px 20px rgb(0 0 0 / 10%));
  transform: rotate(14.244deg);
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  white-space: normal;
  transition: 0.3s all;
  border: none;

  &:hover,
  &:active {
    color: #fff !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, #60b11f 0%, #007829 100%);
  }
}
