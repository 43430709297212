@import '../../../../app/styles/mixins';

.smallIcon {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.campaignElementContainer {
  margin-right: 12px;

  & .campaignElementImage {
    width: 25px;
    height: 32px;
  }

  & .campaignElementCompleteIcon {
    width: 20px;
    height: 20px;

    & > svg {
      width: 12px;
      height: 12px;
    }
  }
}



.textIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 60%;
}

.categorieCardStatus {
  margin-bottom: 0;
  padding: 5px 12px;
  background-color: #f7f7f8;
  margin-left: auto;
}

.categoriesWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  padding: 16px 88px 30px;
  overflow-x: auto;

  @include for-tablet-only {
    padding-inline: 24px;
  }

  @include hide-scrollbar();
}

.categorieCard {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
  min-height: 50px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 16px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.categorieCardSuccessStatus {
  padding: 5px 12px 5px 6px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
