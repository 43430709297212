@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  box-shadow: 0 4px 20px 0 #0000001a;
  cursor: pointer;
  position: relative;
  transition: .2s ease;
  z-index: 0;
  background-color: #ffffff;

  &:hover {
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 25%);
    transform: scale(1.04);
    z-index: 1000;
  }

  @include for-tablet-down {
    flex-direction: column;
  }
}

.wrapperThirdOrMoreWidgets {
  flex: 1;
  flex-direction: column;
}

.pictureWrapper {
  width: 100%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include for-tablet-down {
      width: 100%;
      max-height: 160px;
    }

    @include for-phone-only {
      max-height: 150px;
    }
  }
}

.pictureWrapperThirdOrMoreWidgets {
  width: 100%;

  & > img {
    width: 100%;
    height: 260px;
    object-fit: cover;

    @include for-tablet-down {
      width: 100%;
      max-height: 160px;
    }

    @include for-phone-only {
      max-height: 150px;
    }
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: 300px;

  & .titleContainer {
    display: flex;

    & .objectImage {
      margin-left: 10px;
    }
  }

  @include for-desktop-down {
    padding: 16px;
  }

  @include for-phone-only {
    min-width: 264px;
  }
}

.title[class~='ant-typography'] {
  margin-bottom: 16px;

  @include for-desktop-down {
    font-size: 16px !important;
    margin-bottom: 8px;
  }
}

.description {
  line-height: 20px;

  @include for-desktop-down {
    font-size: 12px !important;
  }
}
