.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  row-gap: 24px;

  & .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -8px;
  }
}