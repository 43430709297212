.card {
  max-width: 1195px;
  min-width: 400px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.subTitleRow {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-bottom: 32px;

  & .subTitle {
    font-size: 14px;
    font-weight: 600;
  }
}

.addPositionButton {
  width: max-content;
  padding: 0;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 600;
  color: #00765F;

  &:hover {
    color: #009F80 !important;
  }

  &:active {
    color: #005a48 !important;
    opacity: 0.8;
  }

  &:disabled {
    &:hover {
      color: #9696A2 !important;
    }
  }
}