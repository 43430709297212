@import '../../../../app/styles/mixins';

.container {
  width: 345px;
  border-radius: 16px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(68, 68, 68, 0.2);
  background: rgba(255, 255, 255, 1);
  margin-top: 14px;
  padding: 20px;

  @include for-phone-only {
    width: 100%;
  }

  & .title {
    font-size: 20px !important;
    color: rgba(74, 74, 74, 1) !important;
  }

  & .elementsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 272px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    & .notFoundElementItem {
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: grayscale(1);
    }

    & .elementItem {
      height: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .foundElement {
        width: 34px;
        height: 44px;
      }

      & .notFoundElement {
        width: 46px;
        height: 46px;
      }
    }
  }

  & .button {
    width: 100%;
    margin-top: 24px;
  }
}

.containerMobile {

}